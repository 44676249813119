import React, {Component} from 'react';
import ListItemMixin from 'components/BaseEntities/ListItemMixin';


export default class BudgetInitiativeProjectList extends Component {
  render() {
    const {items, actions, loading, descriptions, meta, data_mart} = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;
    return (
      <div className={entities_class}>
        {items.map(
          (child, i) =>
            <BudgetInitiativeProjectItem
              key={i}
              data={child}
              meta={meta}
              actions={actions}
              descriptions={descriptions}
              pk={data_mart.pk}
              next_url={data_mart.next_url}
            />
        )}
      </div>
    );
  }
}

class BudgetInitiativeProjectItem extends ListItemMixin(Component) {

  constructor() {
    super();
    this.state = {
      userIsModerator: false
    }
  }

  getItemContent(url, title, infoUrl, data, descriptionBalloon) {
    const {particular_establishment_url, state} = data.extra;
    return (
      <div className="wrap-list-item"
           onClickCapture={e => ::this.handleMouseClick(e)}>
        <div className="row">
          <div className="col-md-12">
            <h4>
              <a href={url}>
                {title}
              </a>
            </h4>
            {this.state.userIsModerator && particular_establishment_url && state === 'resume' ?
              <button className={"btn btn-info btn-sm btn btn-info btn-sm mt-2 mb-1"}
                      onClick={() => location.href = `${particular_establishment_url}#files-block`}>
                Скачать отчет
              </button>
              : null
            }
            {descriptionBalloon}
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.setState({
      userIsModerator: document.querySelector('body').classList.contains('moderateperson')
    })
  }

  render() {
    const {data, descriptions, meta, next_url} = this.props,
      groupSize = data.extra.group_size || 0,
      infoUrl = data.extra?.url || data.entity_url;


    let url = next_url ? `${next_url}?budget_initiative_project_id=${data.id}` : infoUrl;
    url += url.includes('?') ? `&next=${url}` : `?next=${url}`;

    let characteristics = data.short_characteristics || [],
      marks = data.short_marks || [];

    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
    }

    const className = "ex-catalog-item list-item" + (groupSize ? " ex-catalog-item-variants" : "") +
      (descriptions.opened[data.id] ? " ex-state-description" : "");

    const exAttrs = this.getExAttrs(data, characteristics),
      exTags = this.getExTags(marks),
      descriptionBalloon = this.getDescriptionBaloon(data, characteristics, marks, descriptions, exAttrs, exTags) || "",
      title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name,
      itemContent = this.getItemContent(url, title, infoUrl, data, descriptionBalloon);

    return (
      <div className={className}
           onMouseOver={e => this.handleMouseOver(e)}
           onMouseOut={e => this.handleMouseOut(e)}
           style={{minHeight: this.state.minHeight}}>
        {itemContent}
      </div>
    );
  }
}
